<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="API Integration" icon="diagram-project" />

    <div class="generalBox shadow-sm border">
        <div class="row">
            <div class="col-12 mb-3">Application Programming Interface (API) to integrate SignOn with your apps</div>
            <div class="col-12">Coming soon..</div>
        </div>
    </div>

    <div v-if="isSignOn === true" class="generalBox shadow-sm border mt-0">
        <div class="row">
            <div class="col-6 fw-bold mb-2">My API</div>
            <div class="col-6 mb-2 text-end">
                <span class="text-primary isLink" data-bs-toggle="modal" data-bs-target="#mdlAddApi">
                    <fa icon="diagram-project" />
                    <span class="fw-bold me-2" style="font-size: 14px">+</span>Add API
                </span>
            </div>
            <div class="col-12">
                <table class="table table-hover">
                    <tbody>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-5">
                                        <input id="key[0]" ref="key[0]" value="2e17b6c1df874c4ef3a295889ba8dd7170bc56" class="apiKey" />
                                        <fa icon="copy" size="lg" class="isLink ms-2" @click="copy(0)" />
                                    </div>
                                    <div class="col-12 col-lg-2">Sign Request</div>
                                    <div class="col-12 col-lg-2">Hang Kar Meng</div>
                                    <div class="col-12 col-lg-3 text-end">
                                        <span class="mx-3"><fa icon="user" size="lg" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlAddApiMbr" @click="checkedUser = 0"/></span>
                                        <span class="mx-3"><fa icon="gear" size="lg" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlEditApi" @click="editApiType = 'SignReq'" /></span>
                                        <span class="ms-3"><fa icon="trash" size="lg" class="isLink" /></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-5">
                                        <input id="key[1]" ref="key[1]" value="74c4ef3a295889ba20606be9b7c14192c1b3c5" class="apiKey" />
                                        <fa icon="copy" size="lg" class="isLink ms-2" @click="copy(1)" />
                                    </div>
                                    <div class="col-12 col-lg-2">Sign</div>
                                    <div class="col-12 col-lg-2">Hang Kar Meng</div>
                                    <div class="col-12 col-lg-3 text-end">
                                        <span class="mx-3" ><fa icon="user" size="lg" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlAddApiMbr" @click="checkedUser = 0" /></span>
                                        <span class="mx-3"><fa icon="gear" size="lg" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlEditApi" @click="editApiType = 'Sign'" /></span>
                                        <span class="ms-3"><fa icon="trash" size="lg" class="isLink" /></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-5">
                                        <input id="key[0]" ref="key[0]" value="2e17b6c1df874c4ef3a295889ba8dd7170bc56" class="apiKey" />
                                        <fa icon="copy" size="lg" class="isLink ms-2" @click="copy(0)" />
                                    </div>
                                    <div class="col-12 col-lg-2">SSO</div>
                                    <div class="col-12 col-lg-2">Kek Hai Yan</div>
                                    <div class="col-12 col-lg-3 text-end">
                                        <span class="mx-3"><fa icon="user" size="lg" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlAddApiMbr" @click="checkedUser = 3" /></span>
                                        <span class="mx-3"><fa icon="gear" size="lg" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlEditApi" @click="editApiType = 'SSO'" /></span>
                                        <span class="ms-3"><fa icon="trash" size="lg" class="isLink" /></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div v-if="isSignOn === true" class="generalBox shadow-sm border mt-0">
        <div class="row">
            <div class="col-12 fw-bold mb-3">3rd Party Apps Access</div>
            <div class="col-12">

                <div class="accordion" id="myAccordion">
                    <div class="accordion-item" >
                        <div class="accordion-header" id="a1">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac1" aria-expanded="false" aria-controls="ac1">
                                <span style="width:50px" ><img alt="Vue logo" src="../../assets/sample_logo_jobstreet.png" class="align-top" style="height: 35px" /></span>
                                <span>Jobstreet</span>
                            </button>
                        </div>
                        <div id="ac1" class="accordion-collapse collapse" aria-labelledby="a1">
                            <div class="accordion-body">
                               <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="30%">Has access to</td>
                                            <td>
                                                <div>See your personal info</div>
                                                <div>Send invitation includes upload pdf, add signee and add objects</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Homepage</td>
                                            <td>https://www.jobstreet.com</td>
                                        </tr>
                                        <tr>
                                            <td>Access given to</td>
                                            <td>jobstreet.com</td>
                                        </tr>
                                        <tr>
                                            <td>Access given on</td>
                                            <td>45 minutes ago</td>
                                        </tr>
                                    </tbody>
                               </table>
                               <div class="text-center">
                                    <button class="btn btn-secondary"><fa icon="lock" size="lg" class="me-2" />Remove Access</button>
                               </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="accordion-item" >
                        <div class="accordion-header" id="a2">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ac2" aria-expanded="false" aria-controls="ac2">
                                <span style="width:50px" ><img alt="Vue logo" src="../../assets/sample_logo_kk.png" class="align-top" style="height: 35px" /></span>
                                <span>Kakitangan</span>
                            </button>
                        </div>

                        <div id="ac2" class="accordion-collapse collapse" aria-labelledby="a2">
                            <div class="accordion-body">
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td width="30%">Has access to</td>
                                            <td>
                                                <div>See your personal info</div>
                                                <div>See, edit, share and permanently delete all the project and document that you can access</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Homepage</td>
                                            <td>https://www.jobstreet.com</td>
                                        </tr>
                                        <tr>
                                            <td>Access given to</td>
                                            <td>jobstreet.com</td>
                                        </tr>
                                        <tr>
                                            <td>Access given on</td>
                                            <td>24 days ago</td>
                                        </tr>
                                    </tbody>
                               </table>
                               <div class="text-center">
                                    <button class="btn btn-secondary"><fa icon="lock" size="lg" class="me-2" />Remove Access</button>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddApi"
        tabindex="-1"
        aria-labelledby="AddApi"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add API</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <select class="form-select" v-model="apiType">
                                <option value="">Select API</option>
                                <option value="SignReq">Sign Request</option>
                                <option value="Sign">Sign</option>
                                <option value="Workflow">Workflow</option>
                                <option value="SSO">SSO</option>
                            </select>
                        </div>

                        <div class="col-12 mb-3">

                            <div class="table-responsive" style="height: 300px">
                                <table class="table" border="0" v-if="apiType === 'SignReq'">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Upload</td>
                                                                <td>Upload document</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Remove document</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Signee</td>
                                                                <td>Add signee</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Edit signee</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Remove signee</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Signatory</td>
                                                                <td>Add object</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Edit object</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Remove object</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Review</td>
                                                                <td>Add note</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Edit note</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Send sign request</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table" border="0" v-if="apiType === 'Sign'">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Approve / Reject</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Upload signature / initial</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlEditApi"
        tabindex="-1"
        aria-labelledby="EditApi"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Edit API</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <select class="form-select" v-model="editApiType" disabled>
                                <option value="">Select API</option>
                                <option value="SignReq">Sign Request</option>
                                <option value="Sign">Sign</option>
                                <option value="Workflow">Workflow</option>
                                <option value="SSO">SSO</option>
                            </select>
                        </div>

                        <div class="col-12 mb-3">
                            <div class="table-responsive" style="height: 300px">
                                <table class="table" border="0" v-if="editApiType === 'SignReq'">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Upload</td>
                                                                <td>Upload document</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Remove document</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Signee</td>
                                                                <td>Add signee</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Edit signee</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Remove signee</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Signatory</td>
                                                                <td>Add object</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Edit object</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Remove object</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td class="fw-bold" width="30%">Review</td>
                                                                <td>Add note</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Edit note</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td></td>
                                                                <td>Send sign request</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <table class="table" border="0" v-if="editApiType === 'Sign'">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <table class="table table-borderless table-hover">
                                                        <tbody>
                                                            <tr>
                                                                <td>Approve / Reject</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Upload signature / initial</td>
                                                                <td class="text-end"><input v-model="permission" type="checkbox" class="form-check-input" value="" /></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddApiMbr"
        tabindex="-1"
        aria-labelledby="AddApiMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Assign Member</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="search" /></span>
                                <input type="text" class="form-control" placeholder="Member" >
                                <span class="input-group-text clearSearch">
                                    <fa icon="xmark" />
                                </span>
                            </div>
                        </div>

                        <div class="col-12 mb-3 px-3">
                            <div class="table-responsive" style="height: 300px">
                                <table class="table table-hover" border="0">
                                    <tbody>
                                        <tr v-for="(item, itemIdx) in arrUser" :key="item" >
                                            <td width="35px" class="align-middle">
                                                <input v-model="checkedUser" type="radio" class="form-check-input" :value="itemIdx" />
                                            </td>
                                            <td width="50px" class="align-middle">
                                                <ImgAvatar :key="itemIdx" :isUser="true" id="no" displayName="" :isVerified="item.isVerified" :isActive="item.status" privilege="" width="30" height="30" />
                                                <!-- <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" :isActive="item.status" width="30" height="30" /> -->
                                            </td>
                                            <td class="align-middle">
                                                <div class="fw-bold isLink" data-bs-dismiss="modal">
                                                    <router-link :to="'/team/member/' + item.userId">
                                                        <span>{{item.lastName}} {{item.firstName}}</span>
                                                        <span class="text-grey"><fa icon="at" class="mx-1"/>{{ item.displayName }}</span>
                                                    </router-link>
                                                </div>
                                                <div class="small text-grey">{{ item.userId }}</div>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
                
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'ApiIntegration',
    components: {
        TopNavigation, Alert, /* Avatar, */ ImgAvatar
    },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const apiType = ref('') // SignReq/Sign/Workflow/SSO
        const editApiType = ref('') // SignReq/Sign/Workflow/SSO
        const editMember = ref('') // SignReq/Sign/Workflow/SSO

        const checkedUser = ref('')
        const arrUser = ref(null)
        const isSignOn = ref(false) // detect if from signon Entity

        arrUser.value = [
            {
                userId: 'alex.hang@yahoo.com',
                firstName: 'Kar Meng',
                lastName: 'Hang',
                displayName: 'Alex.Hang',
                avatar: '',
                isVerified: false,
                tags: ['colleague', 'development']
            },
            {
                userId: 'mohamad1234@yahoo.com',
                firstName: 'Mohamad bin Abdullah',
                lastName: '',
                displayName: 'Mohamad',
                avatar: '',
                isVerified: true,
                tags: ['colleague', 'cs']
            },
            {
                userId: 'grace@gmail.com',
                firstName: 'Shu Min',
                lastName: 'Tan',
                displayName: 'Grace',
                avatar: '',
                isVerified: false,
                tags: []
            },
            {
                userId: 'kelly@gmail.com',
                firstName: 'Hai Yan',
                lastName: 'Kek',
                displayName: 'Kelly Kek',
                avatar: '',
                isVerified: false,
                tags: ['mcis']
            },
            {
                userId: 'jason.chan@yahoo.com',
                firstName: 'Chun Kiat',
                lastName: 'Lim',
                displayName: 'Jason',
                avatar: '',
                isVerified: false,
                tags: ['unifi']
            },
            {
                userId: 'joey1980@gmail.com',
                firstName: 'Mei Lee',
                lastName: 'Tan',
                displayName: 'Joey80',
                avatar: '',
                isVerified: false,
                tags: ['mcis', 'management']
            },
            {
                userId: 'patrick@gmail.com',
                firstName: 'Xin Zhan',
                lastName: 'Teoh',
                displayName: 'Patrick',
                avatar: '',
                isVerified: false,
                tags: ['colleague', 'support']
            },
            {
                userId: 'yennee.lim@genusis.com',
                firstName: 'Yen Nee',
                lastName: 'Lim',
                displayName: 'YNLim',
                avatar: '',
                isVerified: true,
                tags: ['colleague', 'development']
            },

        ]

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const copy = (id) => {
        // jsonObj.value.focus()
        // document.execCommand('copy')

        // document.getElementById('jsonObj').value = '' + JSON.stringify(box.value, undefined, 2) + ' '
        // document.getElementById('jsonObj').value = '' + JSON.stringify(box.value) + ' '

        console.info('copy fn')
        // console.info('copy id', id, key.value[id])
        document.getElementById('key[' + id + ']').select()
        document.execCommand('copy')
      }

        onMounted(() => {

            func.clearPrevLocalStorage()

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }
        })

        return { 
            route, router, alert, closeAlert, 
            arrUser, checkedUser, apiType, editApiType, editMember,
            copy, isSignOn
        }

    }
}
</script>

<style>
.apiKey {
    width: 90%; 
    color: #6E777E;
    background-color: var(--bgcolor-body-color);
    border-width: 0px;
}

</style>
